@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    box-sizing: border-box;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #473F44;
  }
  h3 {
    font-size: 1.6rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  p, 
  li {
    font-size: 1rem;
    color: #473F44;
    padding-bottom: .5rem;
  }
  .bullet {
    background-color: #473F44;
    border-radius: 100%;
    display: inline-block;
    margin: 0 .5rem 0 0;
    font-size: 1rem;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    color: white;
    line-height: 2rem;
    width: 2rem;
    height: 2rem;
  }
  .message-guide {
    background-color: #D4E6F2;
    border-radius: 0.4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 3rem 0;
    padding: 1rem 2rem;
    width: 100%;
    animation: fadein 2s;
  }
  .logo-bbva {
    width: 110px;
    height: 22px;
    position: absolute;
    top: 0;
    right: 0;
  }
}